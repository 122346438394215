@media  (max-width: 400px) {
    .r {  

    font-size: 2rem !important;   
   
}
    
    }

    .move-text {
        animation: SlideText .8s linear;
      
      }
      
      @keyframes SlideText{
        0%{
          opacity: 0;
          transform: translateY(50px);
        }
      }

.r {
        animation: SlideText .8s linear;
        animation-delay: .4s;
      
      }
      
      @keyframes SlideText{
        0%{
          opacity: 0;
          transform: translateY(50px);
        }
      }

  .ultimate {
        animation: SlideText .8s linear;
        animation-delay: .8s;
      
      }
      
      @keyframes SlideText{
        0%{
          opacity: 0;
          transform: translateY(50px);
        }
      }
    