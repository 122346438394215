.scroll::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .scroll {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

@media only screen and (max-width: 959px) {
    .stepcard{
        flex-direction: column;
    }
  }

.stepcard {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}

.stepcard:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }


/* animation  */


 

.swing {
	transform-origin: top center;
	animation: swing 10s ease infinite;
}
@keyframes swing {
	20% { transform: rotate(3deg); }	
	40% { transform: rotate(-3deg); }
	60% { transform: rotate(3deg); }	
	80% { transform: rotate(-3deg); }	
	
}

.fade-in-down {
  animation: fade-in-down 2s ease infinite;
}
@keyframes fade-in-down {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}



