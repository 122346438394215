
  
  #subscribe{
    background-color: #d0ff00;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
    margin:10px 0px;
    margin-top: 80px;
    padding: 30px 0px;
  }
  #subscribe h3{
  font-size:2.5rem;
  margin:20px;
  color: #000000;
  }
  .subscribe-input{
  width:400px;
  height:50px;
  background-color:#FFFFFF;
  display:flex;
  justify-content: center;
  border-radius: 50px;
  box-shadow: 2px 2px 30px rgba(0,0,0,0.15);
  }
  .subscribe-input input{
  width:100%;
  font-size: 0.9rem;
  background-color: transparent;
  border:none;
  outline: none;
  text-align: center;
  color:#242425;
  }
  .subscribe-input a{
  width:200px;
  height:35px;
  background-color:#896eff;
  color:white;
  display: flex;
  font-size: 0.9rem;
  justify-content: center;
  align-items: center;
  margin: auto 10px;
  border-radius: 20px;
  font-weight: 500;
  transition: 0.4s ease-in;
  }
  .subscribe-input a:hover{
    background-color:#505489;
  color:white;
  }
  @media(max-width:1190px){
  #main{
    background-size: 1150px !important;
    
  }
  
  
  }
  
  @media(max-width:970px){
    
  #subscribe h3{
    font-size: 2.5rem;
  }
  
  
  }
  
  
  @media(max-width:600px){
    #main{
      background-size: 600px !important;
      
    }
  .name{
    width:60%;
    }}
    .scroll{
        left: 42.5%;
    }
  @media(max-width:1100px){
        
        
        
      
        @media(max-width:376px){
          .subscribe-input{
            width:300px;
            height:50px;
            }
            nav .menu-icon{
              position: absolute;
              margin-left: 34.5%;
          }
          }
        }